"use strict";
angular.module("lbbo", [
	"ngRoute",
	"ngMessages",
	"ngSanitize",
	"ngMaterial"
]).config(["$locationProvider", "$routeProvider", function($locationProvider) {
	$locationProvider.hashPrefix("!")
}]).controller("app", ["$scope", "$http", "$mdDialog", "$mdMedia", function($scope, $http, $mdDialog, $mdMedia) {
	$scope.shortLink = {
		original: "",
		extension: "",
		expire: 1
	};
	$scope.user = {
		status: "notLoggedIn"
	};
	$scope.checkExtension = function() {
		$scope.newLinkForm.extension.$setValidity("taken", !1);
		$http({
			url: "app.php",
			method: "GET",
			params: {
				purpose: "checkExtension",
				extension: $scope.shortLink.extension
			}
		}).then(function(responce) {
			//todo test if true and false are in right order
			if(responce.data === 'free') {
				$scope.newLinkForm.extension.$setValidity("taken", true)
			} else {
				$scope.newLinkForm.extension.$setValidity("taken", false)
			}
		}, function(responce) {

		})
	};
	$scope.addExtension = function() {
		var url = ("http" === $scope.shortLink.original.slice(0, 4) ? "" : "http://") + $scope.shortLink.original;
		$http({
			url: "app.php",
			method: "GET",
			params: {
				purpose: "addExtension",
				url: url,
				extension: $scope.shortLink.extension,
				expire: $scope.shortLink.expire
			}
		}).then(function(responce) {
			if (responce.data === "success") {
				var useFullScreen = ($mdMedia("sm") || $mdMedia("xs")) && $scope.customFullscreen;
				$mdDialog.show({
					templateUrl: "templates/successDialog.html",
					parent: angular.element(document.body),
					clickOutsideToClose: true,
					fullscreen: useFullScreen,
					controller: ["$scope", "$mdDialog", function($secondScope, $secondMdDialog) {
						$secondScope.close = $secondMdDialog.hide;
						$secondScope.finalShortLink = window.location.origin + window.location.pathname + $scope.shortLink.extension;
						$secondScope.copyShortLink = function() {
							var finalShortLinkInput = document.querySelector("#finalShortLinkInput");
							document.querySelector("#copyShortLinkToClipboard").addEventListener("click", function() {
								finalShortLinkInput.select();
								try {
									var success = document.execCommand("copy");
									if(!success) {
										alert("Something went wrong, You'll have to copy the link on your own!");
									}
								} catch (error) {
									alert("Something went wrong, You'll have to copy the link on your own!")
								}
							});
						}
					}],
					onComplete: function() {
						document.querySelector("#finalShortLinkInput").select();
					}
				}).then(function() {
					$scope.shortLink = {
						original: "",
						extension: "",
						expire: 1
					}
				});
				$scope.$watch(function() {
					return $mdMedia("xs") || $mdMedia("sm")
				}, function(wantsFullScreen) {
					$scope.customFullscreen = wantsFullScreen === !0
				})
			}
		}, function(responce) {

		});
	};

	$scope.toggleLogin = function() {

	};
}]);